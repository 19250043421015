const data = {
    mainTitle: <>Good food for all</>,
    mainDescription: `Together, Chobani and La Colombe are on a mission: Creating delicious food with high-quality ingredients that define today's trends. Browse Chobani and La Colombe's category-disrupting suite of dairy and non-dairy products.`,

    products: [
        {
            id: 'complete_drinks',
            name: 'High Protein Drinks',
            image: '/images/products_expo24/HighProtein24.png',

            sheets: [
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Drinks_v9-1.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Drinks_v9-2.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Drinks_v9-3.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Drinks_v9-4.png',
            ],
            pdf: '/sheets/2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Drinks_v9.pdf',
        },
        {
            id: 'complete_drinks_cups',
            name: 'High Protein Cups',
            image: '/images/products/ProteinCups.png',

            sheets: [
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Cups_v13-1.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Cups_v13-2.png',
            ],
            pdf: '/sheets/2025/SALES_TM_2025_Expo-West-Sell-Sheets_HPY-Cups_v13.pdf',
        },
        {
            id: 'lc_draft_latte_11oz',
            name: 'Draft Latte 11oz',
            image: '/images/products_la_colombe/LC_11oz_Triple_Expo24.png',

            sheets: [
                '/images/sheets2025/LC25_EXPOWest-SellSheet_11ozCans_FINAL-1.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_11ozCans_FINAL-2.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_11ozCans_FINAL-3.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_11ozCans_FINAL-4.png',
            ],

            pdf: '/sheets/2025/LC25_EXPOWest-SellSheet_11ozCans_FINAL.pdf',
        },
        {
            id: 'lc_draft_latte_9oz',
            name: 'Draft Latte 9oz',
            image: '/images/products_la_colombe/LCDraftLatte.png',

            sheets: [
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9ozCans_FINAL-1.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9ozCans_FINAL-2.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9ozCans_FINAL-3.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9ozCans_FINAL-4.png',
            ],

            pdf: '/sheets/2025/LC25_EXPOWest-SellSheet_9ozCans_FINAL.pdf',
        },
        {
            id: 'lc_draft_latte_4pk',
            name: 'Draft Latte 4-Packs',
            image: '/images/products_la_colombe/LCColdBrew4pack.png',

            sheets: [
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9oz4Pack_FINAL-1.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9oz4Pack_FINAL-2.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9oz4Pack_FINAL-3.png',
                '/images/sheets2025/LC25_EXPOWest-SellSheet_9oz4Pack_FINAL-4.png',
            ],

            pdf: '/sheets/2025/LC25_EXPOWest-SellSheet_9oz4Pack_FINAL.pdf',
        },
        {
            id: 'cold_brew_multi_serve',
            name: 'Cold Brew Multiserve',
            image: '/images/products_la_colombe/LCColdBrew.png',

            sheets: [
                '/images/sheets2025/LC25_EXPOWEST-SellSheet_42ozMultiserve_FINAL-1.png',
                '/images/sheets2025/LC25_EXPOWEST-SellSheet_42ozMultiserve_FINAL-2.png',
            ],

            pdf: '/sheets/2025/LC25_EXPOWEST-SellSheet_42ozMultiserve_FINAL.pdf',
        },
        {
            id: 'greek_yogurt_drinks',
            name: 'Greek Yogurt Drinks',
            image: '/images/products/GreekYogurtDrinks.png',
            sheets: [
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Drinks_v8-1.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Drinks_v8-2.png',
            ],
            pdf: '/sheets/2025/SALES_TM_2025_Expo-West-Sell-Sheets_Drinks_v8.pdf',
        },
        {
            id: 'dairy_creamer',
            name: 'Dairy Creamer',
            image: '/images/products_expo24/DairyCreamer_Expo24.png',

            sheets: [
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Creamer_v7-1.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Creamer_v7-2.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Creamer_v7-3.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Creamer_v7-4.png',
            ],
            pdf: '/sheets/2025/SALES_TM_2025_Expo-West-Sell-Sheets_Creamer_v7.pdf',
        },
        {
            id: 'oatmilk',
            name: 'Oatmilk',
            image: '/images/products/OatmilkOriginal.png',

            sheets: [
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Oatmilk_v10-1.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Oatmilk_v10-2.png',
                '/images/sheets2025/SALES_TM_2025_Expo-West-Sell-Sheets_Oatmilk_v10-3.png',
            ],
            pdf: '/sheets/2025/SALES_TM_2025_Expo-West-Sell-Sheets_Oatmilk_v10.pdf',
        },

        // {
        //     id: 'barista_oatmilk',
        //     name: 'Barista Oatmilk',
        //     image: '/images/products_expo24/BaristaOatmilk_Expo24.png',
        //     // smallText: 'Packaging art not final.',

        //     sheets: [
        //         '/images/sheets/SALES_TM_2024_NACS-Sell-Sheets_Barista_v2_Page_1.png',
        //         '/images/sheets/SALES_TM_2024_NACS-Sell-Sheets_Barista_v2_Page_2.png',
        //     ],
        //     pdf: '/sheets/SALES_TM_2024_NACS-Sell-Sheets_Barista_v2.pdf',
        // },

        /*
        {
            id: 'creations',
            name: 'Creations™',
            image: '/images/products_expo24/Creations_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creations_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creations_v1-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creations_v1',
        },
        {
            id: 'flip',
            name: 'Flip®',
            image: '/images/products_expo24/Flip_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-4.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2.pdf',
        },
        {
            id: 'less_sugar',
            name: 'Less Sugar*',
            image: '/images/products_expo24/LessSugar_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_LSG_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_LSG_v1-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_LSG_v1.pdf',
        },
        {
            id: 'multi_serve',
            name: 'Multi-Serve',
            image: '/images/products_expo24/MultiServe_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-4.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1.pdf',
        },
        
        {
            id: 'oatmilk_limited_batch',
            name: 'Oatmilk Limited Batch',
            image: '/images/products_expo24/CRLMK_Oatmilk_SCA24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-1.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-2.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-3.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-4.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-5.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-6.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk.pdf',
        },
        {
            id: 'zero_sugar',
            name: 'Zero Sugar**',
            image: '/images/products_expo24/ZeroSugar_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_ZS_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_ZS_v1-2.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_ZS_v1.pdf',
        },
        {
            id: 'zero_sugar_drinks',
            name: 'Zero Sugar** Drinks',
            image: '/images/products_expo24/ZeroSugarDrinks_Expo24.png',
            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-4.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-5.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1.pdf',
        },
        {
            id: 'lc_cold_brew_42oz',
            name: 'Cold Brew 42oz',
            image: '/images/products_la_colombe/LC_42oz_Comp_MediumUnsweet_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC-EXPOWEST-SELLSHEET_42ozMULTISERVE-1.png',
                '/images/sheets_la_colombe/LC-EXPOWEST-SELLSHEET_42ozMULTISERVE-2.png',
            ],

            pdf: '/sheets/LC-EXPOWEST-SELLSHEET_42ozMULTISERVE.pdf',
        },
        {
            id: 'lc_coffee_beans',
            name: 'Roasted Coffee',
            image: '/images/products_la_colombe/LC_New_12oz_Packaging_redesign_cutouts_Corsica_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_ROASTED_v2-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_ROASTED_v2-2.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_ROASTED_v2.pdf',
        },
        {
            id: 'lc_k_cup_pods',
            name: 'K-Cup Pods',
            image: '/images/products_la_colombe/LC_Corsica_K-Cup_Amzn3Q_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_KCUP_-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_KCUP_-2.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_KCUP_.pdf',
        },
        */
    ],

    footer: (
        <>
            <br />
        </>
    ),

    copyright: '© March 2025 Chobani, LLC. All Rights Reserved.',
}

export default data
